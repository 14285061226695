<template>
  <input
    ref="input"
    v-model="inputContent"
    :type="type"
    :placeholder="placeholder"
    autocomplete="off"
    class="input"
    @focus="$emit('focus')"
    @input="handleInput"
  />
</template>

<script>
export default {
  name: 'SimpleInput',
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
  },
  data() {
    return {}
  },
  computed: {
    inputContent: {
      get() {
        return this.value
      },
      set(newName) {
        return newName
      },
    },
  },
  methods: {
    handleInput(event) {
      this.$emit('input', event.target.value)
    },
  },
}
</script>

<style lang="sass" scoped>
@import '@/assets/styles/terminalUI/terminalUI.sass'
.input
  width: 100%
  height: 60px
  padding-left: 30px
  border: 1px solid transparent
  border-radius: 30px
  background: $color-white
  font-family: $fontPrimary
  font-weight: 500
  font-size: 20px !important
  color: $mainBlack
  &::placeholder
    color: $greyText
  &:focus, &:hover, &:focus-visible
    outline-offset: unset
    outline: none

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
  -webkit-appearance: none
  margin: 0

/* Firefox */
input[type=number]
  -moz-appearance: textfield
</style>
