var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.type === "checkbox"
    ? _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.inputContent,
            expression: "inputContent",
          },
        ],
        ref: "input",
        staticClass: "input",
        attrs: {
          placeholder: _vm.placeholder,
          autocomplete: "off",
          type: "checkbox",
        },
        domProps: {
          checked: Array.isArray(_vm.inputContent)
            ? _vm._i(_vm.inputContent, null) > -1
            : _vm.inputContent,
        },
        on: {
          focus: function ($event) {
            return _vm.$emit("focus")
          },
          input: _vm.handleInput,
          change: function ($event) {
            var $$a = _vm.inputContent,
              $$el = $event.target,
              $$c = $$el.checked ? true : false
            if (Array.isArray($$a)) {
              var $$v = null,
                $$i = _vm._i($$a, $$v)
              if ($$el.checked) {
                $$i < 0 && (_vm.inputContent = $$a.concat([$$v]))
              } else {
                $$i > -1 &&
                  (_vm.inputContent = $$a
                    .slice(0, $$i)
                    .concat($$a.slice($$i + 1)))
              }
            } else {
              _vm.inputContent = $$c
            }
          },
        },
      })
    : _vm.type === "radio"
    ? _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.inputContent,
            expression: "inputContent",
          },
        ],
        ref: "input",
        staticClass: "input",
        attrs: {
          placeholder: _vm.placeholder,
          autocomplete: "off",
          type: "radio",
        },
        domProps: { checked: _vm._q(_vm.inputContent, null) },
        on: {
          focus: function ($event) {
            return _vm.$emit("focus")
          },
          input: _vm.handleInput,
          change: function ($event) {
            _vm.inputContent = null
          },
        },
      })
    : _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.inputContent,
            expression: "inputContent",
          },
        ],
        ref: "input",
        staticClass: "input",
        attrs: {
          placeholder: _vm.placeholder,
          autocomplete: "off",
          type: _vm.type,
        },
        domProps: { value: _vm.inputContent },
        on: {
          focus: function ($event) {
            return _vm.$emit("focus")
          },
          input: [
            function ($event) {
              if ($event.target.composing) return
              _vm.inputContent = $event.target.value
            },
            _vm.handleInput,
          ],
        },
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }